import {
  AuthApi,
  CMSApi,
  CardsApi,
  ClientsApi,
  CodeListsApi,
  CountriesApi,
  EmployeesApi,
  FullWalletApi,
  HolderAccountsApi,
  ImagesApi,
  MerchantsApi,
  MessagesApi,
  NoticesApi,
  OrdersApi,
  ProductsApi,
  ShellApi,
  TransactionsApi,
  UsersApi,
  WorkPositionsApi,
} from '@odiupsk/up-api-client'
/* eslint-disable import/order */
import { cmsAxios, upAxios } from '../../config'

export const authApi = new AuthApi(upAxios)
export const usersApi = new UsersApi(upAxios)
export const clientsApi = new ClientsApi(upAxios)
export const transactionsApi = new TransactionsApi(upAxios)

export const employeesApi = new EmployeesApi(upAxios)

export const cardsApi = new CardsApi(upAxios)

export const codeListApi = new CodeListsApi(upAxios)

export const countriesApi = new CountriesApi(upAxios)

export const productsApi = new ProductsApi(upAxios)
export const merchantsApi = new MerchantsApi(upAxios)
export const codeListsApi = new CodeListsApi(upAxios)
export const imagesApi = new ImagesApi(upAxios)
export const ordersApi = new OrdersApi(upAxios)
export const workPositionsApi = new WorkPositionsApi(upAxios)
export const noticesApi = new NoticesApi(upAxios)
export const messagesApi = new MessagesApi(upAxios)
export const fullWalletApi = new FullWalletApi(upAxios)

export const cmsApi = new CMSApi(cmsAxios)
export const holderAccountsApi = new HolderAccountsApi(upAxios)
export const shellApi = new ShellApi(upAxios)
